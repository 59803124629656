div.home {
    color: white;
    width: 100%;
    margin: 0;
    margin: 80px 0 80px 0;
}

div.home > div.t {
    text-align: center;
}

div.home > div.t > h1 {
    text-align: center;
    margin: 0 auto 0 auto; 
    font-size: 4.75em;
    line-height: 0.9;
    font-weight: 750;
    font-variation-settings: "wght"725;
    letter-spacing: -0.03em;
    width: fit-content;
}

div.home > div.t > h2 {
    text-align: center;
    margin: 15px auto 0 auto; 
    font-size: 18px;
    font-weight: 550;
    letter-spacing: -0.008em;
    line-height: 1.35;
    width: fit-content;
}

div.home > div.t > button.join {
    margin: 25px auto 0 auto;
    background-color: var(--win);
    height: 40px;
    width: 110px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: white;
}

div.home > div.t > img {
    width: 750px;
    height: auto;
    margin: 50px auto 0px auto;
    display: block;
    position: relative;
    z-index: 1;
}

div.home > div.t > div.vid {
    max-width: 945px;
    width: 100%;
    aspect-ratio: 1.5;
    margin: 0 auto 0 auto;
    border: 1px solid var(--card-border);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background-color: vaR(--dark-bg-1);
    position: relative;
    z-index: 2;
    margin-top: -13px;
}

div.home > div.m {
    margin: 95px auto 0 auto;
    max-width: 945px;
}

div.home > div.m > h1 {
    font-size: 40px;
    font-weight: 750;
    letter-spacing: -1.5px;
    line-height: 1.1;
    width: fit-content;
    margin: 0px 0px 35px 0px;
}

div.home div.cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}
 
div.home div.cards > div.card {
    width: 100%;
    background-color: var(--dark-bg-1);
    border: 1px solid var(--card-border);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    overflow: hidden;
}

div.home div.cards > div.card > img.icon {
    margin: 25px 0 0 25px;
}

div.home div.cards > div.card > img.i {
    width: calc(100% - 50px);
    margin: auto 25px -10px 25px;
}

div.home div.cards > div.card > img.i.learn {
    margin-top: 6px;
}

div.home div.cards > div.card h1 {
    font-size: 18px;
    font-weight: 700;
    margin: 15px 0 10px 25px;
    letter-spacing: -0.008em;
}

div.home div.cards > div.card h2 {
    font-size: 15px;
    font-weight: 500;
    margin: 0 40px 25px 25px;
    letter-spacing: -0.008em;
}

div.home div.cards.small > div.card h2 {
    margin-right: 25px;
}

div.home > div.b > img {
    width: 350px;
    height: auto;
    margin: 0 auto 15px auto;
}

div.home > div.b {
    margin: 95px auto 0 auto;
    width: fit-content;
    text-align: center;
}

div.home > div.b > h1 {
    font-size: 40px;
    font-weight: 750;
    letter-spacing: -1.5px;
    line-height: 1.1;
    width: fit-content;
    margin: 0 auto 0 auto;
}

div.home > div.b > h2 {
    text-align: center;
    margin: 10px auto 0 auto; 
    font-size: 18px;
    font-weight: 550;
    letter-spacing: -0.008em;
    line-height: 1.35;
    width: fit-content;
}

div.home > div.b > button.join {
    margin: 25px auto 0 auto;
    background-color: var(--win);
    height: 40px;
    width: 110px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    font-variation-settings: "wght"600;
    letter-spacing: -0.5px;
    color: white;
}

/* Responsiveness */

@media screen and (max-width: 1000px) {
    div.home div.cards.small {
        grid-template-columns: repeat(2, 1fr);
    }
    div.home {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 850px) {
    div.home {
        width: 90%;
        margin: 60px auto 60px auto;
    }
    div.home > div.t > h1 {
        width: 80%;
        font-size: 60px;
        line-height: 50px;
    }
    div.home > div.t > h2 {
        font-size: 15px;
    }
    div.home > div.t > img {
        width: 90%;
    }
    div.home > div.t > div.vid {
        width: 90%;
    }
    div.home > div.m {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    div.home > div.m > h1 { 
        font-size: 30px;
        letter-spacing: -1px;
    }
    div.home div.cards {
        grid-template-columns: repeat(2, 1fr);
    }
    div.home > div.b {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 95px;
    }
    div.home > div.b > h1 {
        font-size: 28px;
    }
    div.home > div.b > h2 {
        font-size: 15px;
    }
}

@media screen and (max-width: 650px) {
    div.home > div.b > img {
        width: 80%;
    }
}

@media (max-width: 570px) {
    div.home div.cards.big {
        grid-template-columns: repeat(1, 1fr);
    }
    div.home div.cards.small {
        grid-template-columns: repeat(1, 1fr);
    }
}
