@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;550;600;650;700;750;800;900&display=swap');

body, html {
  background-color: black;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, button {
  cursor: pointer;
}

:root {
  --card-border: #4d4d4d;
  --dark-bg-1: #1a1a1a;
  --dark-bg-2: #313131;
  --win: #0bcc42;
  --win-opaque: #0bcc4230;
  --dark-3: #a0a0a0;
}

span.win {
  color: var(--win) !important;
}

button {
  transition: all 0.1s ease-in-out;
}

button:hover {
  transform: scale(1.05);
}