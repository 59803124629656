div.notfound {
    color: white;
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 160px;
    margin-bottom: 80px;
    min-height: calc(70vh - 140px);
}

div.notfound > h1 {
    margin: 0 auto 10px auto;
    text-align: center;
    width: fit-content;
    font-size: 25px;
    font-weight: 750;
    letter-spacing: -0.015em;
}

div.notfound > img {
    height: 150px;
    width: auto;
    max-width: 750px;
    margin: 30px auto 30px auto;
}

div.notfound > h3 {
    width: 100%;
    max-width: 750px;
    text-align: center;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: -0.008em;
    margin: auto;
}

@media screen and (max-width: 500px) {
    div.notfound {
        width: 90%;
        margin: 120px auto 0 auto;
    }
    div.notfound > h1 {
        width: 80%;
        font-size: 20px;
        line-height: 20px;
    }
    div.notfound > h3 {
        font-size: 15px;
        width: 80%;
    }
    div.notfound > img {
        width: 90%;
        height: auto;
    }
}