div.header {
    width: 100%;
    height: 65px;
    background-color: var(--dark-bg-1);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
}

div.header.hme {
    background-color: black;
}

div.header.hme::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    width: calc(100% - 30px);
    height: 1px;
    background-color: var(--card-border);
    transition: all 0.2s ease-in-out;
    opacity: 0;
}

div.header.hme.scrolled::after {
    opacity: 1;
}

div.header > div {
    margin-top: auto;
    margin-bottom: auto;
}

div.header > div.m {
    display: flex;
}

div.header > div.r {
    margin-right: 0px;
    margin-left: auto;
}

div.header > div.l {
    margin-left: 15px;
    width: 38px;
    height: 38px;
    margin-right: 35px;
}

div.header > div.l > a > img {
    width: 38px;
    height: 38px;
}

div.header > div.m > a.hdr-d {
    margin-right: 10px;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    text-decoration: none;
}

div.header > div.m > a.hdr-d.active, div.header > div.m > a.hdr-d:hover {
    background-color: var(--dark-bg-2);
}

div.header > div.m > a.hdr-d > h1 {
    font-size: 15px;
    color: white;
    font-weight: 650;
    letter-spacing: -0.0075em;
    text-decoration: none;
    margin: 0;
}

div.header > div.r > button.join {
    height: 30px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: var(--win);
    padding-left: 12px;
    padding-right: 12px;
    margin-right: 15px;

    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.3px;
    color: white;
}

div.header > div.r > button.menu {
    display: none;
    height: 35px;
    width: 35px;
    margin-right: 15px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: var(--card-border);
    color: white;
    font-size: 20px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
}

div.header > div.r > button.menu > img {
    height: 15px;
    width: 15px;
    margin: auto;
}

div.mbl-menu {
    display: none;
    position: fixed;
    z-index: 5;
    width: 100%;
    height: auto;
    background-color: var(--dark-bg-1);
    margin-top: 65px;
}

div.mbl-menu > a.mbl-menu-nl {
    height: 50px;
    margin: 0;
    background-color: transparent;
    display: flex;
    vertical-align: middle;
    color: white;
    text-decoration: none;
}

div.mbl-menu > a.mbl-menu-nl.active {
    background-color: var(--dark-bg-2);
}

div.mbl-menu > a.mbl-menu-nl.join {
    background-color: var(--win);
}

div.mbl-menu > a.mbl-menu-nl > h1 {
    font-size: 15px;
    margin: auto auto auto 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    letter-spacing: -0.015em;
}

@media screen and (max-width: 650px) {
    div.mbl-menu {
        display: block;
    }
    div.header > div.m {
        display: none;
    }
    div.header > div.r > button.join {
        display: none;
    }
    div.header > div.r > button.menu {
        display: flex;
    }
}
