div.features {
    color: white;
    width: 100%;
    margin: 0;
    margin: 80px 0 80px 0;
    min-height: calc(70vh - 60px);
}

div.features > div.t {
    text-align: center;
}

div.features > div.t > h2 {
    margin: 0 auto 5px auto;
    text-align: center;
    width: fit-content;
    color: var(--dark-bg-2);
    font-size: 20px;
    letter-spacing: -0.015em;
}

div.features > div.t > h1 {
    margin: 0 auto 10px auto;
    text-align: center;
    width: fit-content;
    font-size: 50px;
    font-weight: 750;
    letter-spacing: -1.5px;
}

div.features > div.select {
    display: flex;
    width: fit-content;
    margin: 25px auto 0 auto;
}

div.features > div.select > button {
    margin: 0 15px 0 0;
    height: 50px;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    padding-right: 35px;
    align-items: center;
    display: flex;
    background-color: var(--dark-bg-1);
}

div.features > div.select > button:last-child {
    margin-right: 0px;
}

div.features > div.select > button.s {
    border: 1px solid var(--card-border);
    background-color: transparent;
}

div.features > div.select > button > img {
    margin: 0 10px 0 10px;
}

div.features > div.select > button > img.insights {
margin-top: 2px;
}

div.features > div.select > button > h1 {
    font-size: 15px;
    margin: auto 0 auto 0;
    height: fit-content;
    color: white;
    font-weight: 650;
    letter-spacing: -0.008em;
}

div.features > div.b {
    width: 100%;
    max-width: 750px;
    margin: 35px auto 0 auto;
}

div.features > div.b > img {
    width: 100%;
    aspect-ratio: 2;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    border: 1px solid var(--card-border);
    background-color: var(--dark-bg-1);
}

div.features > div.b > h3 {
    text-align: left;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: -0.008em;
    white-space: pre-wrap;
    margin: 35px 0 0 0;
}

@media screen and (max-width: 850px) {
    div.features { 
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    div.features > div.t > h1 {
        line-height: 55px;
        width: 80%;
    }
    div.features > div.select > button.feature-card {
        padding-right: 25px;
    }
}

@media screen and (max-width: 650px) {
    div.features {
        width: 90%;
        margin: 60px auto 60px auto;
    }
    div.features > div.t > h1 {
        font-size: 35px;
        width: 90%;
        line-height: 40px;
    }
    div.features > div.t > h2 {
        font-size: 15px;
    }
    div.features > div.select {
        display: block;
        width: 90%;
    }
    div.features > div.select > button.feature-card {
        margin-bottom: 10px;
        width: 100%;
    }
    div.features > div.b {
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
}
