div.about {
    color: white;
    width: 100%;
    margin: 0;
    margin: 80px 0 80px 0;
}

div.about > div.t {
    text-align: center;
}

div.about > div.t > h2 {
    margin: 0 auto 5px auto;
    text-align: center;
    width: fit-content;
    color: var(--dark-bg-2);
    font-size: 20px;
    letter-spacing: -0.015em;
}

div.about > div.t > h1 {
    margin: 0 auto 10px auto;
    text-align: center;
    width: fit-content;
    font-size: 50px;
    font-weight: 750;
    letter-spacing: -1.5px;
}

div.about > div.t > img {
    height: 200px;
    width: auto;
    max-width: 750px;
    margin: 30px auto 0 auto;
}

div.about > div.m {
    text-align: center;
    width: 100%;
    max-width: 750px;
    margin: 35px auto 0 auto;
}

div.about > div.m > h3 {
    width: 100%;
    max-width: 750px;
    text-align: left;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: -0.008em;
}

@media screen and (max-width: 850px) {
    div.about { 
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    div.about > div.t > h1 {
        line-height: 45px;
        width: 80%;
    }
    div.about > div.t > img {
        max-width: 450px;
    }
}

@media screen and (max-width: 650px) {
    div.about {
        width: 90%;
        margin: 60px auto 60px auto;
    }
    div.about > div.t > h1 {
        font-size: 35px;
        width: 80%;
        line-height: 35px;
        margin-top: 10px;
    }
    div.about > div.t > h2 {
        font-size: 15px;
    }
    div.about > div.t > img {
        width: 90%;
        height: auto;
    }
    div.about > div.m {
        margin-top: 25px;
    }
    div.about > div.m > h3 {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}