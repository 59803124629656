div.downloads {
    color: white;
    width: 100%;
    margin: 0;
    margin: 80px 0 80px 0;
    min-height: calc(70vh - 60px);
}

div.downloads > div.t {
    text-align: center;
}

div.downloads > div.t > h2 {
    margin: 0 auto 5px auto;
    text-align: center;
    width: fit-content;
    color: var(--dark-bg-2);
    font-size: 20px;
    letter-spacing: -0.015em;
}

div.downloads > div.t > h1 {
    margin: 0 auto 10px auto;
    text-align: center;
    width: fit-content;
    font-size: 50px;
    font-weight: 750;
    letter-spacing: -1.5px;
}

div.downloads > div.m {
    margin: 35px 0 0 0;
}

div.downloads > div.m > div.card {
    width: 100%;
    max-width: 750px;
    margin: 0 auto 40px auto;
}

div.downloads > div.m > div.card > div.top {
    display: flex;
    width: fit-content;
    margin: 0 0 15px 0;
}

div.downloads > div.m > div.card > div.top > img {
    width: 18px;
    height: 18px;
    margin: 2px 10px 0 0;
}

div.downloads > div.m > div.card > div.top > h1 {
    font-size: 20px;
    margin: 0;
}

div.downloads > div.m > div.card > div.bot {
    display: flex;
}

div.downloads > div.m > div.card > div.bot > img {
    height: 110px;
    width: 110px;
    margin: 0 20px 0 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

div.downloads > div.m > div.card > div.bot > div.r {
    height: 110px;
    position: relative;
}

div.downloads > div.m > div.card > div.bot > div.r > h1 {
    font-size: 15px;
    margin: 0 0 5px 0;
}

div.downloads > div.m > div.card > div.bot > div.r > h3 {
    font-size: 15px;
    color: var(--dark-3);
    margin: 0;
    font-weight: 500;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

div.downloads > div.m > div.card > div.bot > div.r > button,
div.downloads > div.m > div.card > div.bot > div.r > a {
    height: 35px;
    width: fit-content;
    font-size: 11px;
    border: 1px solid var(--win);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    background-color: var(--win-opaque);
    color: white;
    font-weight: 600;
    padding: 0 15px 0 15px;
    position: absolute;
    bottom: 0;
    text-decoration: none;
}

div.downloads > div.m > div.card > div.bot > div.r > a {
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in-out;
}

div.downloads > div.m > div.card > div.bot > div.r > a:hover {
    transform: scale(1.05);
}

div.downloads > div.m > div.card > div.bot > div.r > button.hidden {
    opacity: 0.3;
}

div.downloads > div.m > div.card > div.bot > div.r > button.hidden:hover {
    transform: none;
}


@media screen and (max-width: 850px) {
    div.downloads { 
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 650px) {
    div.downloads {
        width: 90%;
        margin: 60px auto 60px auto;
    }
    div.downloads > div.t > h1 {
        font-size: 35px;
        width: 80%;
        line-height: 35px;
        margin-top: 10px;
    }
    div.downloads > div.t > h2 {
        font-size: 15px;
    }
}