div.privacypol {
    color: white;
    width: 100%;
    margin: 0;
    margin: 80px 0 80px 0;
}

div.privacypol > div.t {
    text-align: center;
}

div.privacypol > div.t > h2 {
    margin: 0 auto 5px auto;
    text-align: center;
    width: fit-content;
    color: var(--dark-bg-2);
    font-size: 20px;
    letter-spacing: -0.015em;
}

div.privacypol > div.t > h1 {
    margin: 0 auto 10px auto;
    text-align: center;
    width: fit-content;
    font-size: 50px;
    font-weight: 750;
    letter-spacing: -1.5px;
}

div.privacypol > div.b {
    width: 90%;
    margin: 45px auto 0 auto;
    max-width: 750px;
}

div.privacypol > div.b > h3 {
    width: 100%;
    max-width: 750px;
    text-align: left;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: -0.008em;
}

div.privacypol > div.b > h3.up-date {
    color: var(--card-border);
}

div.privacypol > div.b > h3 > b {
    color: var(--win);
}

div.privacypol > div.b > h3 > a {
    color: var(--win);
}

@media screen and (max-width: 500px) {
    div.privacypol {
        width: 90%;
        margin: 60px auto 60px auto;
    }
    div.privacypol > div.t > h1 {
        font-size: 35px;
        width: 80%;
        line-height: 40px;
    }
}