div.contact {
    color: white;
    width: 100%;
    margin: 0;
    margin: 80px 0 80px 0;
    min-height: calc(70vh - 60px);
}

div.contact > div.t {
    text-align: center;
}

div.contact > div.t > h2 {
    margin: 0 auto 5px auto;
    text-align: center;
    width: fit-content;
    color: var(--dark-bg-2);
    font-size: 20px;
    letter-spacing: -0.015em;
}

div.contact > div.t > h1 {
    margin: 0 auto 10px auto;
    text-align: center;
    width: fit-content;
    font-size: 50px;
    font-weight: 750;
    letter-spacing: -1.5px;
}

div.contact > div.m {
    text-align: center;
    width: 100%;
    max-width: 750px;
    margin: 35px auto 0 auto;
}

div.contact > div.m > div.card {
    width: 100%;
    margin: 0 auto 10px auto;
    background-color: var(--dark-bg-1);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
}

div.contact > div.m > div.card > h1 {
    font-size: 15px;
    margin: 0 auto 0 15px;
    color: var(--win);
}

div.contact > div.m > div.card > a {
    font-size: 15px;
    margin: 0 15px 0 auto;
    text-decoration: underline;
    color: white;
}

div.contact > div.m > h3 {
    width: 100%;
    max-width: 750px;
    text-align: left;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: -0.008em;
}

@media screen and (max-width: 850px) {
    div.contact { 
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 650px) {
    div.contact {
        width: 90%;
        margin: 60px auto 60px auto;
        min-height: calc(100vh - 415px);
    }
    div.contact > div.t > h1 {
        font-size: 35px;
    }
    div.contact > div.t > h2 {
        font-size: 15px;
    }
}