div.footer {
    height: 50px;
    background-color: var(--dark-bg-1);
    max-width: 945px;
    margin: 0 auto 50px auto;
    border-radius: 5px;
    display: flex;
    color: white;
}

div.footer > div.l {
    height: 30px;
    width: 30px;
    margin: auto 50px auto 15px;
}

div.footer > div.l > a > img {
    height: 30px;
    width: 30px;
}

div.footer > div.m {
 display: flex;
 margin: auto auto auto 0px;
}

div.footer > div.m > h2 {
    font-size: 13px;
    font-weight: 600;
    margin-right: 15px;
}

div.footer > div.m > a {
    height: 18.5px;
    margin: auto 12px auto 0;
}

div.footer > div.r {
 display: flex;
 margin: auto 15px auto auto;
}

div.footer > div.r > h2, div.footer > div.r > a {
    font-size: 13px;
    height: fit-content;
    margin: auto;
    font-weight: 600;
    color: white;
    text-decoration: none;
}

div.footer > div.r > a:hover {
    text-decoration: underline;
}

div.footer > div.r > div.dvdr {
    height: 30px;
    width: 2px;
    border-radius: 5px;
    background-color: var(--dark-bg-2);
    margin: auto 15px auto 15px;
}

div.footer > div.r > a.mbl {
    display: none;
}

@media screen and (max-width: 975px) {
    div.footer {
        width: 90%;
    }
}

@media screen and (max-width: 650px) {
    div.footer h1, div.footer h2, div.footer a {
        font-size: 15px !important;
    }
    div.footer {
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        border-radius: 0px;
        height: auto;
        margin-bottom: 0px;
        display: block;
        padding-top: 25px;
        padding-bottom: 40px;
    }
    div.footer > div.r, div.footer > div.m {
        margin-left: 25px;
        margin-top: 15px;
    }
    div.footer > div.m > h2 {
        margin-top: 4px;
        margin-bottom: 4px;
    }
    div.footer > div.r {
        display: block;
    }
    div.footer > div.r > div.dvdr {
        display: none;
    }
    div.footer > div.l {
        margin-bottom: 25px;
        height: auto;
        width: auto;
    }
    div.footer > div.l > a > img {
        height: 40px;
        width: 40px;
    }
    div.footer > div.l > a {
        margin-left: 10px;
    }
    div.footer > div.r > a.mbl {
        display: block;
        margin-bottom: 18px;
    }
    div.footer > div.r > a {
        display: none;
    }
}